/*---------------------------------------
------------------TIPOGRAFÍA-----------
------------------------------------------ */

$font__main: 'Barlow',
sans-serif;
$font__title: 'Montserrat',
sans-serif;
$font__code: Monaco,
Consolas,
"Andale Mono",
"DejaVu Sans Mono",
monospace;
$font__pre: "Courier 10 Pitch",
Courier,
monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.6;

/*---------------------------------------
------------------COLORES------------
------------------------------------------ */

// * Marca
$color__blanco: #fff;
$color__type: #333;
$color__brand: #878787;
$color__brand-alternate: #ccc;
$color__title: #575756;
$color__hover: #000;
$color__link: #9B9B9B;
// * rrss
$color__dribbble: #c7166f;
$color__twitter: #00acee;
$color__medium: #00ab6c;
$color__behance: #1769ff;
$color__instagram: #f09433;
/*Reset*/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: $font__main;
    color: $color__type;
}

body {
    background-color: $color__blanco;
    padding: 0;
    margin: 0 !important;
    width: 100%;
}

h1 {
    font-family: $font__title;
    font-weight: 800;
    font-size: 2em;
}

ul {
    list-style: none;
}

a {
    color: $color__link;
    text-decoration: none;
    transition: color 0.5s ease-in-out;
    &:hover {
        color: $color__hover;
    }
}

i {
    transition: color 0.5s ease-in-out;
}
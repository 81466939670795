/*Index*/

section.landing {
    overflow: hidden;
    padding: 3%;
    margin: 0;
    article.titular {
        margin-top: 4em;
        h2 {
            font-weight: 100;
            margin-left: 1em;
            font-size: 2.8em;
            line-height: 1.2em;
            font-weight: lighter;
            @media (max-width: 992px) {
                font-size: 2em;
            }
        }
    }
    article.contacto {
        margin-left: 0.5em;
        margin-top: 20vh;
        a {
            font-weight: 200;
            font-size: 1.2em;
        }
        ul li {
            display: inline-block;
            margin-left: 2em;
        }
        ul>li>a {
            font-size: 1.6em;
        }
    }
}

section.oso {
    padding: 0;
    margin: 0;
    img {
        height: 99vh;
    }
    @media (max-width:992px) {
        display: none;
    }
}

a.instagram:hover {
    i {
        color: $color__instagram;
    }
}

a.dribbble:hover {
    i {
        color: $color__dribbble;
    }
}

a.twitter:hover {
    i {
        color: $color__twitter;
    }
}

a.medium:hover {
    i {
        color: $color__medium;
    }
}

a.behance:hover {
    i {
        color: $color__behance;
    }
}

.mt2 {
    @media (max-width: 992px) {
        margin-top: 2em;
    }
    margin-top: 0em;
}
/*---------------------------------------
------------------MIXINS-MASTER-----------
------------------------------------------ */
// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}


/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
	@if is-direction($value) == false {
		@error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
	}

	$conversion-map: (
		to top			: bottom,
		to top right	: bottom left,
		to right top	: left bottom,
		to right		: left,
		to bottom right : top left,
		to right bottom : left top,
		to bottom		: top,
		to bottom left	: top right,
		to left bottom	: right top,
		to left			: right,
		to left top		: right bottom,
		to top left		: bottom right
		);

	@if map-has-key($conversion-map, $value) {
		@return map-get($conversion-map, $value);
	}

	@return 90deg - $value;
}

/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
	$is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
	$is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

	@return $is-keyword or $is-angle;
}



@mixin card($depth) {
	@if $depth < 1 {
		box-shadow: none;
	} @else if $depth > 5 {
		@warn "Invalid $depth `#{$depth}` for mixin `card`.";
	} @else {
		box-shadow: bottom-shadow($depth), top-shadow($depth);
	}
}


/// Computes a top-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function top-shadow($depth) {
	$primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
	$blur: nth(1.5 3 10 14 19, $depth) * 4px;
	$color: rgba(black, nth(.12 .16 .19 .25 .30, $depth));

	@return 0 $primary-offset $blur $color;
}

/// Computes a bottom-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function bottom-shadow($depth) {
	$primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
	$blur: nth(1 3 3 5 6, $depth) * 4px;
	$color: rgba(black, nth(.24 .23 .23 .22 .22, $depth));

	@return 0 $primary-offset $blur $color;
}

